
import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddCompanyModal from "@/components/modals/forms/AddCompanyModal.vue";
import AddAccountModal from "@/components/modals/forms/AddAccountModal.vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, { business_types } from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import { useRouter } from "vue-router";
import moment from "moment";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "customers-listing",
  components: {
    ExportCustomerModal,
    AddAccountModal,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const count = ref(0);
    const checkedCompany = ref([]);
    const loadingData = ref<boolean>(false);
    var ids = ref(0);

    interface WICompanies {
      id;
      ledger_name;
      invoice_no
      order_no;
      product_description;
      product_total_amount;
      igst_amount;
      cgst_amount;
      sgst_amount;
      grand_total_amount;
      approved_date;
      closing_balance;
    }

    const formData = ref({
      startDate: "",
      endDate: "",
    });

    var paginationData = ref({});

    paginationData.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      pageRange: [1],
    };

    const refreshData = () => {
      getReports();
    };

    const chkData = () => {
      console.log(fromDate.value);
    };

    const changePageChange = (page) => {
      console.log(page);
      paginationData.value["activePage"] = page;
      getReports();
    };

    var tableData = ref<Array<WICompanies>>([]);

    const getReports = async () => {
      //loadingData.value = true;
      var user = JSON.parse(JwtService.getToken());
      var start_data = ""
        var end_date = ""
      if(!formData.value['startDate']){
          start_data = ""
          end_date = ""
      }
      else
      {
        start_data = moment(formData.value.startDate[0]).format("YYYY-MM-DD")
        end_date = moment(formData.value.startDate[1]).format("YYYY-MM-DD")
      }
      try {
        var values = {
          user_id : user.user_id,
          start_date: start_data,
          end_date: end_date,
          page: parseInt(paginationData.value["activePage"]) || 1,
          records_per_page: parseInt(paginationData.value["perPage"]),
        };
        console.log(values);
        await store
          .dispatch(ActionsFi.CUST_GET_PURCHASE_REPORT, values)
          .then(({ data }) => {
          tableData.value = [];
            //const totalCount = data?.data?.total_records;
            // set pagination
            paginationData.value["total"] = data.total_records;
            paginationData.value["start"] = data.records_from;
            paginationData.value["end"] = data.records_upto;
            paginationData.value["activePage"] = data.page;
            paginationData.value["totPage"] = data.total_pages;
            paginationData.value["pageRange"] = [];
            for (
              let index = data.bar_range_start;
              index < data.bar_range_end;
              index++
            ) {
              paginationData.value["pageRange"].push(index);
            }
            //endpagination
            //data = data?.data?.result_list;

            var resultsM = ref<Array<WICompanies>>([]);
            var status_label = "";
            var status_color = "";
            var active_label = "";
            var active_color = "";

            for (let j = 0; j < data.result_list.length; j++) {
              // if (data.data[j]["active"]) {
              //   active_label = "Yes";
              //   active_color = "success";
              // } else {
              //   active_label = "No";
              //   active_color = "danger";
              // }

              resultsM.value = Array({
                id: data.result_list[j]["id"],
                ledger_name: data.result_list[j]["ledger_name"],
                invoice_no: data.result_list[j]["invoice_no"],
                order_no: data.result_list[j]["order_no"],
                product_description: data.result_list[j]["product_description"],
                product_total_amount: data.result_list[j]["product_total_amount"],
                igst_amount: data.result_list[j]["igst_amount"],
                cgst_amount: data.result_list[j]["cgst_amount"],
                sgst_amount: data.result_list[j]["sgst_amount"],
                grand_total_amount: data.result_list[j]["grand_total_amount"],
                approved_date: data.result_list[j]["approved_date"],
                closing_balance: data.result_list[j]["closing_balance"],
              });

              tableData.value.splice(
                j,
                resultsM.value.length,
                ...resultsM.value
              );
            }
            loadingData.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    // function fnAPI(){

    //   companies_data = [
    //     {
    //       id: Math.floor(Math.random() * 99999) + 1,
    //       name: " (India) llp",
    //       email: "admin@metalxperts.com",
    //       company: "-",
    //       payment: {
    //         icon: "/media/svg/card-logos/visa.svg",
    //         ccnumber: "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
    //         label: "visa",
    //       },
    //       date: "01 Dec 2020, 10:12 am",
    //     }
    //   ];

    //   console.log(companies_data);
    //   tableData = ref<Array<ICompanies>>(companies_data);
    //   initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
    //   console.log(tableData);

    // }

    onUpdated(() => {
      // console.log("aaap");
      // console.log(list_data);
    });

    // var tableData = ref<Array<WICompanies>>([]);
    const initCustomers = ref<Array<WICompanies>>([]);

    onMounted(async () => {
      await getReports();
      setCurrentPageBreadcrumbs("Purchase Ledger Report", []);
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
      console.log(tableData.value.length);
    });

    const deleteFewCustomers = () => {
      checkedCompany.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedCompany.value.length = 0;
    };

    const deleteCustomer = (id) => {
      // for (let i = 0; i < tableData.value.length; i++) {
      //   if (tableData.value[i].account_id === id) {
      //     tableData.value.splice(i, 1);
      //   }
      // }
    };

    const search = ref<string>("");
    const fromDate = ref<string>("");
    const toDate = ref<string>("");
    const creditTotal = ref<string>("");
    const debitTotal = ref<string>("");
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      if (search.value !== "") {
        let results: Array<WICompanies> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    return {
      tableData,
      deleteCustomer,
      search,
      searchItems,
      checkedCompany,
      deleteFewCustomers,
      count,
      getReports,
      paginationData,
      changePageChange,
      refreshData,
      loadingData,
      fromDate,
      toDate,
      chkData,
      creditTotal,
      debitTotal,
      formData,
    };
  },
});
